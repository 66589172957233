import axios from 'axios';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export async function GetUserData(request, token) {

    try {
        const response = await axios.get(`${backend}/fetch/data`, {
            headers: {
                authorization: `Bearer ${token}`,
                request,
            },
        });

        return response;
    }
    catch (err) {
        console.error(err);
    };
};
