export function shapeTableArray(arr, operation) {

    let updatedArray = [];

    if (operation === "sortByWins") {
        // Count wins for each account
        const winsCountMap = arr.reduce((acc, item) => {
            const accountName = item.account_name;
            if (!acc[accountName]) {
                acc[accountName] = 0;
            }
            if (item.result === "won") {
                acc[accountName] += 1;
            }
            return acc;
        }, {});


        // Sort by wins, then by account_name
        const newArray = [...arr].sort((a, b) => {
            const winsA = winsCountMap[a.account_name] || 0;
            const winsB = winsCountMap[b.account_name] || 0;

            // Sort by wins in descending order
            if (winsA !== winsB) {
                return winsB - winsA;
            }

            // If wins are equal, sort by account_name in ascending order
            const accountA = a.account_name ? a.account_name : '';
            const accountB = b.account_name ? b.account_name : '';
            return accountA.localeCompare(accountB);
        });

        updatedArray = newArray;
        return updatedArray;
    }

    if (operation === "hideBust") {
        // Create a map to count the number of "lost" results for each account
        const lostCountMap = arr.reduce((acc, item) => {
            const accountName = item.account_name;
            if (!acc[accountName]) {
                acc[accountName] = 0;
            }
            if (item.result === "lost") {
                acc[accountName] += 1;
            }
            return acc;
        }, {});

        // Filter out accounts that have two or more "lost" results
        const newArray = arr.filter(item => {
            const accountName = item.account_name;
            return lostCountMap[accountName] < 2;
        });

        updatedArray = newArray;
        return updatedArray;
    }

    if (operation === "sortAtoZ") {
        // Sort by account_name in ascending order
        const newArray = [...arr].sort((a, b) => {
            const accountA = a.account_name ? a.account_name.toLowerCase() : '';
            const accountB = b.account_name ? b.account_name.toLowerCase() : '';
            return accountA.localeCompare(accountB);
        });

        updatedArray = newArray;
        return updatedArray;
    }

    return updatedArray;
}
