import { useContext } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context
import { useNavigate } from "react-router-dom";
import { AccountContainer } from "../components/accountContainer";
import { TableNew } from '../components/tableNew';
import { FixtureList } from '../components/fixtureList';

export function Selection() {

  const { selectedCompetition } = useContext(AppContext); // Use context for login

  const navigate = useNavigate(); // Move this outside the condition

  // Add a loading state to check if data is available
  if (!selectedCompetition || Object.keys(selectedCompetition).length === 0) {
    return <div>Loading...</div>;  // Or a spinner component
  };

  const roundFull = selectedCompetition && selectedCompetition.gameParams?.round;
  const round = roundFull && Number(roundFull.split(" - ")[1]);
  const startRound = Number(selectedCompetition?.compParams?.startRound?.split(" - ")[1]);
  const gameWeekText = `Current Fixtures: Game Week ${round}`;
  const view = "individual";


  function onStandings() {
    navigate('/standings');
  };

  const pageColour = selectedCompetition ? selectedCompetition.compParams?.home_background : '#FFFFFF';

  return (
    <main style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: '100%',
      height: '100%',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
      backgroundColor: `#${pageColour}`,
    }}>
      <div style={{
        display: 'flex',
        paddingTop: '3%',
        paddingBottom: '3%',
        margin: '0',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `#${pageColour}`,
        width: '100 %'
      }}>
        {selectedCompetition.name === 'TestComp' ? <img src="/dummies.jpg" alt="dummies" className="banner-img" /> : <img src="/pirates_banner.jpg" alt="Logo" className="banner-img" />}
      </div>
      <AccountContainer />
      <div className="header-container">
        {selectedCompetition?.gameParams?.round ? (round < startRound) &&
          <>
            <h3>COMPETITION STARTS: Game Week {startRound}</h3>
            <h1>You are not required to make a selection yet</h1>
            <h1>Game Week {startRound} will open no later than 12:30PM Tue 19th November. Selections will need to be made before the first fixture starts at 12:30PM on Sat 23rd November</h1>
          </> : null}
        <h3>{gameWeekText}</h3>
        <button type="button" onClick={onStandings}>Competition Standings</button>
        <h2>Your selections:</h2>
        <div className="selection-history">
          <TableNew
            round={round}
            view={view}
          />
        </div>
      </div>
      <FixtureList />
    </main>
  )
};