import axios from 'axios';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export async function UpdateSelectedAccount(accountName, selectedCompetitionName, token) {

    try {
        const response = await axios.post(`${backend}/update/selectedaccount`,
            { accountName, selectedCompetitionName },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response;
    } catch (err) {
        console.error(err);
        return null; // Ensure something is returned in case of error
    }
}
