import { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppProvider'; // Import AppProvider context

export function CreateCompetition() {
    const [view, setView] = useState('intro');
    const { authenticatedUser } = useContext(AppContext);
    const navigate = useNavigate(); // Initialize useNavigate

    const competitionNameRef = useRef(null);
    const [lives, setLives] = useState(2);
    const [ticketPrice, setTicketPrice] = useState(10000);
    const [startWeek, setStartWeek] = useState('gameweek1');
    const [expectedEntrants, setExpectedEntrants] = useState(100);
    const [payoutPercentage, setPayoutPercentage] = useState(10);

    function handleClick(nextView) {
        setView(nextView);
    }

    function createCompObject() {
        return {
            name: competitionNameRef.current?.value || '',
            gameParams: {
                league: 39,
                season: 2024,
                round: "",
                current: true,
                active: true
            },
            compParams: {
                pin: "",
                unitPrice: ticketPrice,
                startRound: `Regular Season - ${startWeek.split("gameweek")[1]}`,
                payout: payoutPercentage,
                home_image: "",
                home_text: "",
                home_background: "",
                creator: authenticatedUser.name,
                lives,
                expectedEntrants,
                stripeAccountId: authenticatedUser.stripe.id
            },
            fixtures: []
        };
    }

    function Intro({ onNext }) {
        return (
            <>
                <h1>Create a competition</h1>
                <h2>Thanks for completing the Stripe onboarding!</h2>
                <br />
                <h2>Let's start by entering the basic competition parameters...</h2>
                <br />
                <button type="button" onClick={onNext}>Next</button>
                <br />
                <button type="button" onClick={() => navigate('/competitions')}>Cancel</button>
            </>
        );
    }

    function StepOne({ onNext }) {
        return (
            <form>
                <h3>Competition name:</h3>
                <input ref={competitionNameRef} />

                <h3>Number of lives:</h3>
                <select value={lives} onChange={(e) => setLives(Number(e.target.value))}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>

                <h3>Entry fee:</h3>
                <select value={ticketPrice} onChange={(e) => setTicketPrice(Number(e.target.value))}>
                    {[...Array(20).keys()].map(i => (
                        <option key={`fee${(i + 1) * 5}`} value={(i + 1) * 5000}>
                            £{(i + 1) * 5}.00
                        </option>
                    ))}
                </select>

                <h3>Start week:</h3>
                <select value={startWeek} onChange={(e) => setStartWeek(e.target.value)}>
                    {[...Array(25).keys()].map(i => (
                        <option key={`startWeek${i + 1}`} value={`gameweek${i + 1}`}>Gameweek {i + 1}</option>
                    ))}
                </select>

                <h3>Expected competition entrants:</h3>
                <input
                    type="range"
                    value={expectedEntrants}
                    min={0}
                    max={1000}
                    step={25}
                    onChange={(e) => setExpectedEntrants(Number(e.target.value))}
                />
                <h4>{expectedEntrants} entrants</h4>

                <h3>Prize payout percentage:</h3>
                <input
                    type="range"
                    value={payoutPercentage}
                    min={5}
                    max={90}
                    step={1}
                    onChange={(e) => setPayoutPercentage(Number(e.target.value))}
                />
                <h4>{payoutPercentage}%</h4>

                <button type="button" onClick={() => {
                    const competitionObj = createCompObject();
                    navigate('/compStyle', { state: { competitionObj } }); // Navigate and pass the competition object
                }}>Next</button>
            </form>
        );
    }

    return (
        <>
            {view === 'intro' && <Intro onNext={() => handleClick('stepOne')} />}
            {view === 'stepOne' && <StepOne onNext={() => handleClick('stepTwo')} />}
        </>
    );
}
