import axios from 'axios';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export async function UpdateSelectedTeam(selectionObj, token) {
    const { round, date, team_id, result, competitionName } = selectionObj
    try {
        const response = await axios.post(`${backend}/update/selections`,
            { round, date, team_id, result, competitionName },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response;
    } catch (err) {
        console.error(err);
        return null; // Ensure something is returned in case of error
    }
}
