import { useState, useContext } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context
import { startStripeConnectOnboarding } from '../components/connectAccount'; // Assuming this is where onboarding logic resides
import { useNavigate } from 'react-router-dom';
import { CreateCompetition } from './createCompetition';


export function Onboarding() {
    const { authenticatedUser } = useContext(AppContext);

    const onboardingStarted = authenticatedUser?.stripe?.id && authenticatedUser.stripe.onboardingComplete !== true;

    const [view, setView] = useState(
        !authenticatedUser?.stripe
            ? 'intro'
            : onboardingStarted
                ? 'partially_onboarded'
                : authenticatedUser.stripe.onboardingComplete
                    ? 'onboarded'
                    : 'intro'
    );


    function handleClick(nextView) {
        setView(nextView);
    }

    function submitOnboarding(formData) {
        startStripeConnectOnboarding(formData);
    }

    return (

        <main style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            height: '100%',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            backgroundColor: '#fff'
        }}>
            <div style={{
                display: 'flex',
                paddingTop: '3%',
                paddingBottom: '3%',
                margin: '0',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}>
                <img src={"/logo.png"} alt="Logo" className={"banner-img-LMS"} />
            </div>
            <div className='competition-container'>
                {/* Render the components based on the current view */}
                {view === 'onboarded' && <CreateCompetition />}
                {view === 'partially_onboarded' && <PartiallyOnboarded />}
                {view === 'intro' && <Intro onNext={() => handleClick('onboard')} />}
                {view === 'onboard' && <OnboardForm onSubmit={submitOnboarding} />}
            </div>
        </main >

    );

    function PartiallyOnboarded() {
        const navigate = useNavigate();

        return (
            <>
                <h2>The onboarding process was not fully completed. Please complete the remaining actions before creating a competition.</h2>
                <p></p>
                <button type="button" onClick={() => navigate(authenticatedUser.stripe.accountLink)}></button>
                <p></p>
                <button type="button" onClick={() => navigate('/competitions')}>Cancel</button>
                <p></p>
                <br />
            </>
        )
    }

    function Intro({ onNext }) {
        const navigate = useNavigate()
        return (
            <>
                <h2>To become a Competition Admin and create a Last Man Standing competition, you must first register with the Stripe payment platform to securely route competition entry fees to your bank account.</h2>
                <p></p>
                <button type="button" onClick={onNext}>Let's Go!</button>
                <p></p>
                <button type="button" onClick={() => navigate('/competitions')}>Cancel</button>
                <p></p>
                <br />
            </>
        );
    }

    function OnboardForm({ onSubmit }) {
        const [businessType, setBusinessType] = useState('individual');
        const [companyName, setCompanyName] = useState('');
        const [companyUrl, setCompanyUrl] = useState('');
        const [individualFirstName, setIndividualFirstName] = useState('');
        const [individualLastName, setIndividualLastName] = useState('');
        const [email, setEmail] = useState('');
        const [organisationName, setOrganisationName] = useState('');
        const navigate = useNavigate();

        const handleSubmit = () => {
            const formData = {
                authenticatedUser,
                businessType,
                companyName,
                companyUrl,
                individualFirstName,
                individualLastName,
                email,
                organisationName,
            };
            onSubmit(formData);
        };

        return (
            <div>
                {/* Form inputs for Stripe onboarding */}
                <h3>Account type:</h3>
                <select value={businessType} onChange={(e) => setBusinessType(e.target.value)}>
                    <option value="individual">Individual</option>
                    <option value="company">Company</option>
                    <option value="non_profit">Non-profit</option>
                </select>
                {businessType === 'company' && (
                    <>
                        <h3>Company name:</h3>
                        <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    </>
                )}
                {businessType === 'non_profit' && (
                    <>
                        <h3>Organisation name:</h3>
                        <input value={organisationName} onChange={(e) => setOrganisationName(e.target.value)} />
                    </>
                )}
                <br></br>
                <br></br>
                <h3>{businessType === 'individual' ? 'First name' : 'Contact first name'}:</h3>
                <input value={individualFirstName} onChange={(e) => setIndividualFirstName(e.target.value)} />
                <br></br>
                <br></br>
                <h3>{businessType === 'individual' ? 'Last name' : 'Contact last name'}:</h3>
                <input value={individualLastName} onChange={(e) => setIndividualLastName(e.target.value)} />
                <br></br>
                <br></br>
                {companyUrl === 'companyURL' && (
                    <>
                        <h3>Company website:</h3>
                        <input value={companyUrl} onChange={(e) => setCompanyUrl(e.target.value)} />
                    </>
                )}
                <h3>Email:</h3>
                <input value={email} onChange={(e) => setEmail(e.target.value)} />
                <br></br>
                <br></br>
                <br></br>
                <button type="button" onClick={handleSubmit}>Submit Onboarding</button>
                <p></p>
                <button type="button" onClick={() => navigate('/competitions')}>Cancel</button>
            </div>
        );
    }
}
