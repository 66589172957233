import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function CompStyle() {
    const location = useLocation();
    const { competitionObj } = location.state || {};

    // State for rendering values
    const [homeImage, setHomeImage] = useState("https://pixabay.com/illustrations/banner-header-floral-butterfly-1477373/");
    const [homeText, setHomeText] = useState('Use this space to welcome/thank competition entrants, or however you see fit!');
    const [homeBackground, setHomeBackground] = useState('#ffffff');

    // Refs for form inputs
    const homeImageRef = useRef(null);
    const homeTextRef = useRef(null);

    // Function to apply changes
    const applyChanges = () => {
        setHomeImage(homeImageRef.current.value);
        setHomeText(homeTextRef.current.value);
        // Note: homeBackground is directly updated by the input field
    };

    return (
        <main style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            backgroundColor: homeBackground
        }}>
            <div style={{
                display: 'flex',
                padding: '3%',
                justifyContent: 'center',
                width: '100%'
            }}>
                <img src={homeImage} alt="Banner" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>

            <div className='leagues-home-container'>
                <h1>{`Welcome to ${competitionObj?.name}'s Last Man Standing competition!`}</h1>
                <h2>{homeText}</h2>
            </div>

            <Parameters
                homeImageRef={homeImageRef}
                homeTextRef={homeTextRef}
                homeBackground={homeBackground} // Pass the current background color directly
                setHomeBackground={setHomeBackground} // Pass the setter for background color
                onApply={applyChanges}
            />
        </main >
    );

    function Parameters({ homeImageRef, homeTextRef, homeBackground, setHomeBackground, onApply }) {
        return (
            <div className="competition-container">
                <form>
                    <h3>Banner image:</h3>
                    <input
                        type="text"
                        ref={homeImageRef} // Assign ref
                        placeholder="Enter home image URL"
                    />
                    <h3>Welcome text:</h3>
                    <textarea
                        ref={homeTextRef} // Assign ref
                        placeholder="Enter home text"
                        rows={4}
                        style={{ width: '100%', resize: 'none' }}
                    />
                    <h3>Background colour:</h3>
                    <input
                        type="color"
                        value={homeBackground} // Controlled by state
                        onChange={(e) => setHomeBackground(e.target.value)} // Update state on change
                    />
                    <br />
                    <p></p>
                    <p></p>
                    <button type="button" onClick={onApply}>Apply Changes</button>
                </form>
            </div>
        );
    }
}
