import axios from 'axios';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export async function startStripeConnectOnboarding(newLeagueObj) {
    console.log(newLeagueObj);
    try {
        const newAccount = await axios.post(`${backend}/stripe/createaccount`, newLeagueObj, {
            headers: {
                authorization: `Bearer ${newLeagueObj.authenticatedUser.token}`,
            },
        });
        console.log(newAccount.data); // Use newAccount.data to access the response data
        return newAccount.data; // You may want to return the account data or handle it as needed
    } catch (err) {
        // Determine the type of error and display a meaningful message
        let errorMessage;

        if (axios.isAxiosError(err)) {
            console.log(err.message);
            // Handle Axios errors
            errorMessage = err.response?.data?.message || 'An error occurred while connecting to Stripe. Please try again.';
        } else {
            // Handle unexpected errors
            errorMessage = 'An unexpected error occurred. Please try again.';
        }

        console.error(err); // Log the error for debugging
        alert(errorMessage); // Display the error message to the user
    }
}
