export function UserTable({ data, columnMappings, dateColumns, excludeColumns = [] }) {
    if (data.length === 0) return <p>No data available</p>;

    // Get column headers from the keys of the first data object and apply mappings
    const headers = Object.keys(data[0]).filter(header => !excludeColumns.includes(header));
    const mappedHeaders = headers.map(header => columnMappings[header] || header);

    // Format date columns
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' });
    };

    // Conditional cell background color
    const getCellStyle = (header, value) => {
        if (
            (header === 'verified' && value === false) ||
            (header === 'password_reset' && value === true)) {
            return { backgroundColor: '#ffb3b3', border: '1px solid lightgrey', padding: '8px', textAlign: 'center' };
        }
        if (
            (header === 'accountsNamed' && value === false)
        ) {
            return { backgroundColor: '#fff2cc', border: '1px solid lightgrey', padding: '8px', textAlign: 'center' };
        }
        return { border: '1px solid lightgrey', padding: '8px', textAlign: 'center', backgroundColor: '#f2f2f2' };
    };

    return (
        <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid lightgrey' }}>
            <thead>
                <tr>
                    {mappedHeaders.map((header, index) => (
                        <th key={index} style={{ border: '1px solid lightgrey', padding: '6px', textAlign: 'center', backgroundColor: '#3b54c8', color: 'white' }}>
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {headers.map((header, colIndex) => (
                            <td key={colIndex} style={getCellStyle(header, row[header])}>
                                {dateColumns.includes(header) && row[header] ? formatDate(row[header]) : row[header]?.toString() || ""}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default UserTable;
