import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import InitiatePayment from "../components/payment";
import { UpdateSelectedComp } from '../components/updateSelectedComp';
import { UserTable } from '../components/UserTable';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export function Competitions() {
    const { authenticatedUser, selectedCompetition, toggleCompetitionChanged } = useContext(AppContext); // Use context for login

    // Screen view toggles
    const [view, setView] = useState('choose'); // 'choose', 'purchase', 'rename'
    const [noCompFound, setNoCompFound] = useState(false);

    // Input state
    const [quantity, setQuantity] = useState(1);
    const [joinCompetitionObj, setJoinCompetitionObj] = useState({});

    const navigate = useNavigate();

    // Add a loading state to check if data is available
    if (!authenticatedUser || typeof toggleCompetitionChanged !== 'function') {
        return <div>Loading...</div>;  // Or a spinner component
    }

    // User.competitions data
    const userCompetitions = authenticatedUser.competitions;
    const stateSelectedCompetitionExists = Object.keys(selectedCompetition).length !== 0;
    const userSelectedCompetition = userCompetitions && userCompetitions.find(
        (competition) => competition.selected === true
    );

    const nonJoined = !userSelectedCompetition;

    const userCompMatchesStateComp = !nonJoined && stateSelectedCompetitionExists && userCompetitions.find(
        (competition) => competition.competition_id === selectedCompetition._id
    );

    const numberOfAccounts = userCompMatchesStateComp && userSelectedCompetition.numberOfAccounts;
    const accountsNamed = userCompMatchesStateComp && userSelectedCompetition.accountsNamed;
    const email = authenticatedUser && authenticatedUser.email;
    const adminUser = email && /marshamech|radio|burke80|michael_treadwell/.test(email);

    function handlePinError() {
        setNoCompFound(true);
    }

    function handleShowChooseCompetition() {
        setView('choose');
    }

    function handlePurchaseAccounts() {
        setView('purchase');
    }

    function handleShowRenameAccounts() {
        setView('rename');
    }

    /*
    function handleCreateCompetition() {
        navigate('/onboarding')
    }
*/

    function onStandings() {
        navigate('/standings');
    }

    function onSelections() {
        accountsNamed ? navigate('/selection') : alert('Please name your account(s) first!');
    }

    const onSubmit = async (event) => {
        event.preventDefault();
    };

    // Update selected competition
    const handleCompetitionSelection = async (compName) => {
        try {
            const result = await UpdateSelectedComp(compName, authenticatedUser.token);
            if (result.status === 200) {
                toggleCompetitionChanged(true);
                console.log(result.data.message);  // Access message from result.data
            } else if (result.status === 403) {
                console.log('Token invalid or expired');
            }
        } catch (err) {
            console.error('Error fetching user data:', err);
        }
    };

    const backGroundColour = nonJoined ? '#fff' : `#${selectedCompetition.compParams?.home_background || 'ffffff'}`; // Add fallback in case home_background is missing

    // Conditionally render components based on the view state
    return (
        <main style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            height: '100%',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            backgroundColor: backGroundColour
        }}>
            <div style={{
                display: 'flex',
                paddingTop: '3%',
                paddingBottom: '3%',
                margin: '0',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}>
                {nonJoined ? <img src={"/logo.png"} alt="Logo" className={"banner-img-LMS"} /> : selectedCompetition.name === 'TestComp' ? <img src="/dummies.jpg" alt="dummies" className="banner-img" /> : <img src="/pirates_banner.jpg" alt="Logo" className="banner-img" />}
            </div>

            {
                !nonJoined &&
                <div className='leagues-home-container'>
                    <h1>{`Welcome to ${selectedCompetition?.name}'s Last Man Standing competition`}!</h1>
                    <h2>{selectedCompetition.compParams?.home_text}</h2>
                    <br></br>
                </div>
            }

            {/* Render the components based on the current view */}
            {view === 'choose' && <ChooseCompetition />}
            {view === 'purchase' && <PurchaseAccounts />}
            {view === 'rename' && <RenameAccounts />}
        </main >
    );


    function ChooseCompetition() {
        const [competitionPin, setCompetitionPin] = useState("");
        const [newComplessUsers, setNewComplessUsers] = useState([]); // State to store fetched users
        const [allCompUsers, setAllCompUsers] = useState([]); // State to store fetched users

        function handlePinInput(event) {
            setCompetitionPin(event.target.value);
        }

        async function getNewUsers(createdDate) {
            try {
                const response = await axios.get(`${backend}/fetch/newusers`, {
                    headers: {
                        authorization: `Bearer ${authenticatedUser.token}`
                    },
                    params: {
                        createdDate,
                        compName: selectedCompetition?.name
                    }
                });

                // Update state with fetched users
                setNewComplessUsers(response.data.payload.newComplessUsers);
                setAllCompUsers(response.data.payload.allCompUsers);

            } catch (err) {
                console.error(err);
            }
        };

        // Use useEffect to trigger the fetch when the component mounts
        useEffect(() => {
            // Only fetch new users if adminUser is true and states are initially empty
            if (adminUser) {
                getNewUsers("2024-11-06");
            }

            console.log(newComplessUsers);
            console.log(allCompUsers);

        }, [newComplessUsers, allCompUsers]);

        // Define custom column mappings and settings
        const withoutCompetitionMappings = {
            password_reset: 'forgot pass',
            createdDate: 'registered',
            //lastLoginDate: 'last login'
        };
        const withCompetitionMappings = {
            accountsNamed: 'entries named',
            password_reset: 'forgot pass',
            purchaseDate: 'joined',
            //lastLoginDate: 'last login',
            numberOfAccounts: 'accounts'
        };

        const dateColumns = ['createdDate', /*'lastLoginDate',*/ 'purchaseDate'];

        const totalNumberOfAccounts = allCompUsers.reduce((sum, user) => sum + (user.numberOfAccounts || 0), 0);


        async function handlePinSubmit() {
            setNoCompFound(false); // Reset before submission
            if (!competitionPin) {
                return alert("Please enter Competition Pin");
            }

            if (competitionPin && authenticatedUser) {
                try {
                    const result = await axios.get(`${backend}/fetch/competition`, {
                        headers: {
                            authorization: `Bearer ${authenticatedUser.token}`,
                            request: competitionPin,
                        },
                    });

                    if (result.status === 204) {
                        setCompetitionPin(""); // Clear the input field
                        handlePinError();
                        return;
                    } else if (result.status === 200) {
                        console.log(`200 - ${result.data.message}`);
                        const { compParams, gameParams } = result.data.payload;
                        const round = parseInt(gameParams.round.split('-')[1].trim(), 10);
                        const startRound = parseInt(compParams.startRound.split('-')[1].trim(), 10);

                        if (round > startRound) {
                            alert("You cannot create further accounts as this competition has already started.");
                            return;
                        }

                        setJoinCompetitionObj(result.data.payload);
                        handlePurchaseAccounts();
                    }
                } catch (error) {
                    if (error.response) {
                        console.error('Server responded with an error:', error.response.data);
                        console.error('Status code:', error.response.status);
                        swal(error.response.data.message || "An error occurred while processing your request.");
                    } else if (error.request) {
                        console.error('No response received:', error.request);
                        swal("No response from the server. Please check your internet connection and try again.");
                    } else {
                        console.error('Error setting up request:', error.message);
                        swal("An unexpected error occurred. Please try again later.");
                    }
                }
            }
        };

        return (
            <>
                <div className="competition-container">
                    <h1>Your competitions</h1>
                    <h2>Select to change the active competition:</h2>
                    {userCompetitions && (
                        <select
                            style={{ textAlign: 'center' }}
                            value={selectedCompetition?.name || ""}
                            onChange={(event) => handleCompetitionSelection(event.target.value)}
                        >
                            {userCompetitions.length > 0
                                ? userCompetitions.map((obj, index) => (
                                    <option key={`${obj.name}-${index}`} value={obj.name}>
                                        {obj.name}
                                    </option>
                                ))
                                : null}
                        </select>
                    )}
                    {nonJoined && (
                        <>
                            <p></p>
                            <h3>{`(You haven't joined any competitions yet!)`}</h3>
                        </>
                    )}
                    <p></p>
                    {!accountsNamed && !nonJoined && (
                        <button type="button" onClick={handleShowRenameAccounts}>
                            Name accounts
                        </button>
                    )}
                    {accountsNamed && !nonJoined && (
                        <div className="leagues-home-nav-buttons">
                            <button type="button" onClick={onSelections}>
                                Selections
                            </button>
                            <p></p>
                            <button type="button" onClick={onStandings}>
                                Standings
                            </button>
                        </div>
                    )}
                </div>

                <div className="competition-container">
                    <h1>Join a competition</h1>
                    <h2>Enter the 'joining pin' given to you by your competition admin:</h2>
                    <input type="text" value={competitionPin} onChange={handlePinInput} />
                    <p></p>
                    {noCompFound && competitionPin.length < 1 && (
                        <>
                            <h2>{`Competition Pin not found! Please double-check the Pin provided by your Competition Admin and try again.`}</h2>
                            <p></p>
                        </>
                    )}
                    <button type="button" onClick={handlePinSubmit}>
                        Join competition
                    </button>
                    <p></p>
                </div>

                {adminUser && (
                    <>
                        <div className="leagues-home-container">
                            <h3>All users JOINED the Competition</h3>
                            <div className="header-stats">
                                <h4>Total users: {allCompUsers.length}</h4>
                                <h4>Total accounts: {totalNumberOfAccounts}</h4>
                            </div>                            <UserTable
                                data={allCompUsers}
                                columnMappings={withCompetitionMappings}
                                dateColumns={dateColumns}
                                excludeColumns={['createdDate']}
                            />
                        </div>
                        <div className="leagues-home-container">
                            <h3>New Users NOT Joined the Competitions</h3>
                            <div className="header-stats">
                                <h4>Total: {newComplessUsers.length}</h4>
                            </div>
                            <UserTable
                                data={newComplessUsers}
                                columnMappings={withoutCompetitionMappings}
                                dateColumns={dateColumns}
                            />
                        </div>
                    </>
                )}

                {/*<div className="competition-container">
                    <h1>Create a competition</h1>
                    <h2>Click here to create a competition!</h2>
                    <p></p>
                    <button type="button" onClick={handleCreateCompetition}>
                        Create competition
                    </button>
                    <p></p>
                </div>*/}

            </>
        );
    }

    function PurchaseAccounts() {
        const compObj = joinCompetitionObj;
        const compParams = compObj?.compParams;
        const name = compObj?.name;
        const id = compObj?.id;

        const unitPrice = compParams?.unitPrice;
        const totalPrice = unitPrice && unitPrice * quantity;
        const totalPricePounds = totalPrice && totalPrice / 100;

        const lineItems = [
            {
                quantity,
                currency: 'GBP',
                unit_amount: unitPrice,
                competitionName: name,
                competition_id: id,
                description: `${quantity} entries for ${name}'s LMS prize competition.`,
                images: [],
            },
        ];

        if (!unitPrice) {
            return (
                <div className="competition-checkout-container">
                    <h1>Cannot Proceed</h1>
                    <p>You cannot purchase accounts as the current round has surpassed the start round.</p>
                    <button type="button" onClick={handleShowChooseCompetition}>Go back</button>
                </div>
            );
        }

        return (
            <>
                <div className="competition-checkout-container">
                    <form onSubmit={onSubmit}>
                        <h1>{name}</h1>
                        <h2>Please select the desired number of game entries. </h2>
                        <h2>Each entry will receive their own two lives, e.g. 'John Smith', 'John Smith's Dad', 'Mrs Smith', etc. </h2>
                        <h2>Multiple entries are only required for users wishing to manage multiple competition entries under their single platform account/login.</h2>
                        <p></p>
                        <h2> 1 x competition entry = £{(unitPrice / 100).toFixed(2)} </h2>
                        <select value={quantity} onChange={(event) => setQuantity(event.target.value)}>
                            {[...Array(10).keys()].map(i => (
                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                        <p></p>
                        <h3>Total to pay: £{totalPricePounds.toFixed(2)}</h3>
                        <p></p>
                        <InitiatePayment lineItems={lineItems} authenticatedUser={authenticatedUser} setView={setView} />
                    </form>
                    <p></p>
                    <button type="button" onClick={handleShowChooseCompetition}>Go back</button>
                </div>
            </>
        );
    }

    function RenameAccounts() {
        const [accounts, setAccounts] = useState(Array(numberOfAccounts).fill(''));
        const [error, setError] = useState('');

        const onSubmit = async (event) => {
            event.preventDefault();

            // Ensure all account names are filled
            if (accounts.includes('')) {
                return alert("Please enter an account name for each of your purchased accounts");
            }

            try {
                const result = await axios.post(`${backend}/authenticate/registeraccounts`, {
                    accounts,
                    email,
                    selectedCompetition,
                });

                if (result.data.status === "SUCCESS") {
                    alert("Account names submitted. You can now select between your accounts on the 'Selections' page. Good luck and happy gaming!");
                    toggleCompetitionChanged(true);
                    setError(''); // Clear error if submission is successful
                    handleShowChooseCompetition();
                } else {
                    setError(result.data.message);
                }
            } catch (err) {
                console.error(err);
                setError("An error occurred while submitting account names.");
            }
        };

        const handleAccountInputChange = (event, index) => {
            const newAccounts = [...accounts];
            newAccounts[index] = event.target.value;
            setAccounts(newAccounts);
        };

        if (!numberOfAccounts || numberOfAccounts <= 0) {
            return <div>No accounts to rename.</div>;
        }

        return (
            <div className="competition-container">
                <h1>Register Accounts</h1>
                <div className="form">
                    <p></p>
                    <h2>Here you can enter a name for each of your accounts purchased for this league.</h2>
                    <p></p>
                    <h2>For users with multiple entries to the league, the account names will help you identify each different entry.</h2>
                    <p></p>
                    <h2>Account names will appear in the competition standings table which is visible to all players - therefore please keep all account names clean!</h2>

                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            {Array.from({ length: numberOfAccounts }, (_, index) => (
                                <div key={`account-${index}`}>
                                    <h3>Rename account {index + 1}:</h3>
                                    <input
                                        id={`account${index + 1}`}
                                        type="text"
                                        placeholder={`e.g. "${authenticatedUser.username} ${index + 1}"`}
                                        value={accounts[index]}
                                        onChange={(event) => handleAccountInputChange(event, index)}
                                        required
                                    />
                                </div>
                            ))}
                        </div>
                        <button type="submit">Register</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
                <p></p>
                <button type="button" onClick={handleShowChooseCompetition}>Return</button>
            </div>
        );
    }

}