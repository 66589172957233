import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useState, useContext } from 'react';
import { AppContext } from '../AppProvider'; // Import AppProvider context
// Backend URI
const backend = process.env.REACT_APP_SERVER_URI;



// Stripe public key
const stripePromise = loadStripe(
    process.env.REACT_APP_NODE_ENV !== 'dev'
        ? 'pk_live_51NzHtBImvAtotwI3pj47YSkyemjlvF4B5VpP0wpQAm6Xz9KvqM4AUjP8dx6GMVwmZZtTXITI2i81Qt6j4gHYtMSN00si2qBAD9'
        : 'pk_test_51NzHtBImvAtotwI3qcK6mfUq8pNx0xs7OCfOCcBNJHIWFRmejRvGVGCPcJd41o3LU84CKSarcECBlCftGzFJGbQ300FEL1RdfI'
);

const CheckoutForm = ({ lineItems, setView }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState(null);
    const { authenticatedUser, toggleCompetitionChanged } = useContext(AppContext); // 


    const handlePayment = async () => {
        try {
            const response = await fetch(`${backend}/stripe/createpaymentintent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lineItems,
                    email: authenticatedUser.email,
                    userName: authenticatedUser.username,
                }),
            });

            const { clientSecret } = await response.json();

            if (!clientSecret) {
                console.error("Failed to get client secret from backend");
                return;
            }

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        email: authenticatedUser.email,
                        name: authenticatedUser.username,
                    },
                },
            });

            if (error) {
                setPaymentError(error.message);
                console.error(error.message);
            } else if (paymentIntent.status === 'succeeded') {
                // Payment was successful
                console.log('Payment succeeded');
                alert("Payment successful!");
                setView('choose');
                toggleCompetitionChanged(true)
            }
        } catch (error) {
            console.error("Payment failed:", error);
            setPaymentError("An error occurred during payment. Please try again.");
        }
    };

    return (
        <div className="payment-form">
            <div className="form-group">
                <label>Card Number</label>
                <CardNumberElement className="card-element" />
            </div>
            <div className="form-group">
                <label>Expiry Date</label>
                <CardExpiryElement className="card-element" />
            </div>
            <div className="form-group">
                <label>CVC</label>
                <CardCvcElement className="card-element" />
            </div>
            <button type="button" onClick={handlePayment} disabled={!stripe}>
                Pay Now
            </button>
            {paymentError && <div className="payment-error">{paymentError}</div>}
        </div>
    );
};

// Wrap the CheckoutForm in Elements with the stripePromise
const InitiatePayment = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm {...props} />
        </Elements>
    );
};

export default InitiatePayment;
