import axios from 'axios';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export async function UpdateSelectedComp(compName, token) {
    try {
        const response = await axios.post(`${backend}/update/selectedcompetition`,
            { compName },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response;
    } catch (err) {
        console.error(err);
        return null; // Ensure something is returned in case of error
    }
}
